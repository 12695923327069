import { createApp } from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBars, faXmark, faCaretLeft, faCaretRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import BodyScrollLock from '@/plugins/BodyScrollLock';
import EscapeDetector from '@/plugins/EscapeDetector';
import App from './App.vue';
import router from './router';

library.add(faBars, faXmark, faCaretLeft, faCaretRight);

const app = createApp(App);
app.component('font-awesome-icon', FontAwesomeIcon)
  .use(router)
  .use(BodyScrollLock)
  .use(EscapeDetector)
  .mount('#app');
