import { computed, reactive, ref } from 'vue';

const currentMessageId = ref(0);
const currentMessage = ref('');

const getMessageId = computed((): number => currentMessageId.value);
const getMessage = computed((): string => currentMessage.value);

function changeMessage(newMessage: string): void {
  currentMessageId.value = Math.random();
  currentMessage.value = newMessage;
}

const alertStore = reactive({
  getMessageId,
  getMessage,
  changeMessage,
});

export default alertStore;
