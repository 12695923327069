import { App, DirectiveBinding } from 'vue';

function disableBodyScroll() {
  document.body.classList.add('scroll-locked');
}

function enableBodyScroll() {
  document.body.classList.remove('scroll-locked');
}

export default {
  install: (app: App) => {
    app.directive('scroll-lock', {
      mounted(el: HTMLElement, binding: DirectiveBinding) {
        if (binding.value) {
          disableBodyScroll();
        }
      },

      updated(el: HTMLElement, binding: DirectiveBinding) {
        if (binding.value) {
          disableBodyScroll();
        } else {
          enableBodyScroll();
        }
      },

      unmounted() {
        enableBodyScroll();
      },
    });
  },
};
