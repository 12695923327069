<template>
  <div class="banner">
    <div class="banner__content">
      This page is a work in progress.
    </div>
  </div>
</template>
<script setup lang="ts">

</script>
<style scoped>
.banner {
  position: relative;
  display: flex;
  justify-content: center;
  font-weight: bold;
  padding: 12px;
}
.banner__content {
  padding: 4px 8px;
  background: var(--background-body);
  border: var(--border);
  border-radius: 4px;
}
.banner::after {
  content: "";
  position: absolute;
  left: calc(50% - 50vw);
  width: 100vw;
  height: 100%;
  top: 0;
  --orange: #ffa74a;
  background: var(--orange);
  background-size: 50px 50px;
  z-index: -1;
}
@media (prefers-color-scheme: dark) {
  .banner::after {
    --orange: #9d6325;
  }
}
</style>
