import { App, DirectiveBinding } from 'vue';

// The type of callback accepted by this directive
type CallbackFunction = () => void

// Variables to track the state of this detector
let windowListenerAdded = false;
const eventCallbacks = new Map<HTMLElement, CallbackFunction>();

/**
 * Calls tracked callbacks when appropriate.
 *
 * This is called every time a key is pressed. If that key is ESC,
 * it calls all the callbacks currently stored.
 *
 * @param e A keyboard event that could be Escape.
 */
function eventHandler(e: KeyboardEvent): void {
  if (e.code !== 'Escape') return;
  eventCallbacks.forEach((cb) => {
    cb();
  });
}

/**
 * This adds a new callback. It adds a listener only if none exist already.
 * @param el The element requesting this callback (only necessary for removing the callback later)
 * @param cb The function to call when ESC is pressed
 */
function addCallback(el: HTMLElement, cb: CallbackFunction): void {
  if (!windowListenerAdded) {
    window.addEventListener('keydown', eventHandler);
    windowListenerAdded = true;
  }
  eventCallbacks.set(el, cb);
}

/**
 * This removes an existing callback. It removes the listener if there are no callbacks.
 * @param el The element that originally requested this callback
 */
function removeCallback(el: HTMLElement): void {
  eventCallbacks.delete(el);

  if (windowListenerAdded && eventCallbacks.size === 0) {
    window.removeEventListener('keyup', eventHandler);
    windowListenerAdded = false;
  }
}

export default {
  install: (app: App) => {
    app.directive('on-esc', {
      mounted(el: HTMLElement, binding: DirectiveBinding) {
        if (binding.value) {
          addCallback(el, binding.value);
        }
      },

      updated(el: HTMLElement, binding: DirectiveBinding) {
        if (binding.value) {
          addCallback(el, binding.value);
        } else {
          removeCallback(el);
        }
      },

      unmounted(el: HTMLElement) {
        removeCallback(el);
      },
    });
  },
};
