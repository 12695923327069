import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import PageNotFound from '@/views/PageNotFound.vue';
import Home from '@/views/HomePage.vue';

// Routes to lazy-load
const WordleHelper = () => import('@/views/WordleHelper.vue');
const VacationPlanner = () => import('@/views/VacationPlanner.vue');
const TaskTracker = () => import('@/views/TaskTracker.vue');

// For typing the meta field
declare module 'vue-router' {
  interface RouteMeta {
    navTitle: string,
    headerTitle: string,
    browserTitle: string,
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      navTitle: 'Home',
      headerTitle: 'Applet Orchard',
      browserTitle: 'Applet Orchard',
    },
  },
  {
    path: '/wordle-helper',
    name: 'wordle-helper',
    component: WordleHelper,
    meta: {
      navTitle: 'Wordle Helper',
      headerTitle: 'Wordle Helper',
      browserTitle: 'Wordle Helper - Applet Orchard',
    },
  },
  {
    path: '/vacation-planner',
    name: 'vacation-planner',
    component: VacationPlanner,
    meta: {
      navTitle: 'Vacation Planner',
      headerTitle: 'Vacation Planner',
      browserTitle: 'Vacation Planner - Applet Orchard',
    },
  },
  {
    path: '/task-tracker',
    name: 'task-tracker',
    component: TaskTracker,
    meta: {
      navTitle: 'Task Tracker',
      headerTitle: 'Task Tracker',
      browserTitle: 'Task Tracker - Applet Orchard',
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'page-not-found',
    component: PageNotFound,
    meta: {
      navTitle: 'Page Not Found',
      headerTitle: 'Page Not Found',
      browserTitle: 'Page Not Found - Applet Orchard',
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Set the title when navigating between views
const DEFAULT_TITLE = 'Applet Orchard';
router.afterEach((to) => {
  if (to.meta?.browserTitle) {
    document.title = to.meta.browserTitle;
  } else {
    document.title = DEFAULT_TITLE;
  }
});

export default router;
